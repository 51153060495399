<template>
	<main>
		<div class="setting-box">
			<div class="tab">
				<el-tabs v-model="tab" @change="changeTab">
					<el-tab-pane name="1" label="项目配置"></el-tab-pane>
					<el-tab-pane name="2" label="获取代码"></el-tab-pane>
					<el-tab-pane name="3" label="通知设置"></el-tab-pane>
					<el-tab-pane name="4" label="其他设置"></el-tab-pane>
				</el-tabs>
			</div>
			<div class="tab-main">
				<div class="tab-item tab-item-1" v-show="tab === '1'">
					<el-form :model="form" :rules="rules" ref="form" label-width="100px">
						<div class="form-flex">
							<el-form-item label="项目名称" prop="title">
								<el-input v-model="form.title"></el-input>
							</el-form-item>
							<el-form-item label="项目类型" prop="type">
								<el-select class="el-block" v-model="form.type" placeholder="请选择">
									<el-option label="JAVASCRIPT" value="js"></el-option>
									<el-option label="VUE" value="vue"></el-option>
									<el-option label="VUE3" value="vue3"></el-option>
									<el-option label="REACT" value="react"></el-option>
									<el-option label="WXAPP" value="wxapp"></el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="form-flex">
							<el-form-item label="监控console" prop="console">
								<el-switch v-model="form.console"></el-switch>
							</el-form-item>
							<el-form-item label="监控xhr" prop="xhr">
								<el-switch v-model="form.xhr"></el-switch>
							</el-form-item>
						</div>
						<div class="form-flex">
							<el-form-item label="监控fetch" prop="console">
								<el-switch v-model="form.fetch"></el-switch>
							</el-form-item>
							<el-form-item label="行为记录" prop="max_bread">
								<el-input-number v-model="form.max_bread" :max="100" placeholder="20"></el-input-number>
							</el-form-item>
						</div>
						<el-form-item label="忽略接口地址" prop="filter_ajax">
							<el-input v-model="form.filter_ajax" placeholder="支持正则"></el-input>
						</el-form-item>
						<div class="form-flex">
							<el-form-item label="状态" prop="status">
								<el-switch v-model="form.status" active-text="正常" inactive-text="停用"></el-switch>
							</el-form-item>
							<el-form-item label="权重" prop="sort">
								<el-input v-model="form.sort" type="number"></el-input>
							</el-form-item>
						</div>
						<div class="form-flex">
							<el-form-item label="发布时间" prop="create_time">
								<el-date-picker v-model="form.create_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
							</el-form-item>
							<el-form-item label="停用时间" prop="end_time">
								<el-date-picker v-model="form.end_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
							</el-form-item>
						</div>
						<el-form-item >
							<el-button type="primary" @click="submit" :loading="submit_loading">保存</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div class="tab-item tab-item-2" v-show="tab === '2'">
					<el-form label-width="100px">
						<el-form-item label="探针代码">
							<div class="code">{{code}}</div>
						</el-form-item>
						<el-form-item label="部署说明">
							<div class="tip">
								<p>1. 复制探针代码，将探针代码插入到 <code>&lthead&gt</code> 标签里面</p>
								<p>2. 部署即时生效</p>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="tab-item tab-item-3" v-show="tab === '3'">
					<el-form label-width="100px">
						<el-form-item label="通知类型">
							<el-select class="el-block" v-model="form.notice_type" multiple>
								<el-option v-for="(item,index) in sentry_type" :key="index" :value="item" :label="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="通知间隔">
							<el-input-number v-model="form.notice_step"/> <i>(分钟)</i>
						</el-form-item>
						<el-form-item label="通知状态">
							<el-switch v-model="form.notice_status" active-text="正常" inactive-text="停用"></el-switch>
						</el-form-item>
						<el-form-item label="通知邮箱">
							<div class="list">
								<div class="li" v-for="(item,index) in receiver" :key="index">
									<div class="text">{{item}}</div>
									<el-popconfirm title="确定要删除该邮箱吗？" @confirm="removeEmail(item)">
										<el-icon slot="reference" class="el-icon-delete"></el-icon>
									</el-popconfirm>
								</div>
							</div>
							<el-button type="primary" @click="showEmail">添加邮箱</el-button>
						</el-form-item>
						<el-form-item >
							<el-button type="primary" @click="submit" :loading="submit_loading">保存</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div class="tab-item tab-item-2" v-show="tab === '4'">
					<el-form label-width="100px">
						<el-form-item label="删除项目">
							<el-button type="danger" @click="deleteProject">删除项目</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<el-dialog title="添加邮箱" :visible="show_email" @close="hideEmail">
			<el-form ref="email_form" :model="email_form" :rules="email_rules" label-width="100px">
				<el-form-item label="邮箱地址" prop="email">
					<el-input v-model="email_form.email" ></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code">
					<el-row>
						<el-col :span="12">
							<el-input v-model="email_form.code" ></el-input>
						</el-col>
						<el-col :span="10" style="margin-left: 10px;">
							<el-button :loading="code_loading" @click="sendCode" :disabled="code_hidden">{{code_text}}</el-button>
						</el-col>
					</el-row>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideEmail">取 消</el-button>
				<el-button type="primary" @click="submitEmail">确 定</el-button>
			</div>
		</el-dialog>
	</main>
</template>
<script>
import {code, project} from "@/api/sentry";
import {stringToTime, timeToString} from "@/service/admin/common";
import _ from "lodash";
import {sentry} from "@/config";

export default {
	data(){
		return{
			query:{},
			tab:"1",
			form:{},
			submit_loading:false,
			sentry_type:sentry.type,

			show_email:false,
			email_form:{},
			code_text:"获取验证码",
			code_loading:false,
			code_hidden:false,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入名称"}
				],
				type:[
					{required:true,message:"请选择类型"}
				]
			}
		},
		email_rules(){
			return {
				email:[
					{required:true,message:"请输入邮箱地址"},
					{type:'email',message:"请输入正确的邮箱地址"},
				],
				code:[
					{required:true,message:"请输入验证码"}
				]
			}
		},
		receiver(){
			return this.form.notice_receiver? this.form.notice_receiver.split(',') : [];
		},
		code(){
			var script = `<script type="text/javascript" src="${this.form.lib}"><\/script>`;
			var config = {
				dsn:this.form.dsn,
				apikey:this.form.key,
				silentConsole: !this.form.console,
				silentXhr: !this.form.xhr,
				silentFetch: !this.form.fetch,
				filterXhrUrlRegExp: this.form.filter_ajax || '',
				maxBreadcrumbs:this.form.max_bread || 20
			}
			var type = "";
			var plugin = "";
			switch (this.form.type){
				case 'vue':
					type = `"vue":Vue,`;
					plugin = `,[MITO.vuePlugin]`;
					break;
				case 'vue3':
					type = `"vue":app,`;
					plugin = `,[MITO.vuePlugin]`;
					break;
				default:
					break;
			}

			return script+`<script type="text/javascript">MITO.init({"debug":false,"disabled":false,${type}"dsn":"${config.dsn}","apikey":"${config.apikey}","silentConsole":${config.silentConsole},"silentXhr":${config.silentXhr},"silentFetch":${config.silentFetch},"filterXhrUrlRegExp":"${config.filterXhrUrlRegExp}","maxBreadcrumbs":${config.maxBreadcrumbs}}${plugin});<\/script>`
		}
	},
	created() {
		this.query = this.$route.query;
	},
	mounted() {
		this.getProjectDetail();
	},
	methods:{
		deleteProject(){
			this.$confirm('确定要删除该项目吗？').then((res)=>{
				project.delete({
					id:this.form.id
				}).then((res)=>{
					this.$message.success('删除成功');
					this.$router.replace('/sentry')
				}).catch((error)=>{
					this.$message.error(error.message)
				})
			})
		},
		showEmail(){
			this.show_email = true;
		},
		hideEmail(){
			this.show_email = false;
			this.email_form = {};
			this.$forceUpdate();
		},
		sendCode(){
			if(this.code_loading){
				return false;
			}
			this.$refs['email_form'].validateField(['email'],(valid) => {
				if(valid){
					return false;
				}
				this.code_loading = true;
				code.email({
					event:'sentry_bind',
					email:this.email_form.email
				}).then((res)=>{
					this.code_hidden = true;
					this.cutTime();
				}).catch((error)=>{
					this.$message.error(error.message)
				}).finally(()=>{
					this.code_loading = false;
				})
			});
		},
		cutTime(){
			let time = 60;
			clearInterval(this.timer);
			const one = ()=>{
				time = time-1;
				if(time <= 0){
					clearInterval(this.timer);
					this.code_text = '获取验证码';
				}else{
					this.code_text = "重新获取("+time+")"
				}
			}
			this.timer = setInterval(()=>{
				one();
			},1000);
			one();
			this.$once('beforeDestroy',()=>{
				clearInterval(this.timer)
			})
		},
		submitEmail(){
			this.$refs['email_form'].validate((valid) => {
				if (!valid) {
					return false;
				}
				project.addNoticeEmail({
					id:this.form.id,
					event:"sentry_bind",
					email:this.email_form.email,
					code:this.email_form.code
				}).then((res)=>{
					this.hideEmail();
					this.getProjectDetail();
					this.$message.success('添加成功')
				}).catch((error)=>{
					this.$message.error(error.message)
				})
			});
		},
		removeEmail(email){
			project.removeNoticeEmail({
				id:this.form.id,
				email:email,
			}).then((res)=>{
				this.getProjectDetail();
				this.$message.success('删除成功')
			}).catch((error)=>{
				this.$message.error(error.message)
			})
		},
		changeTab(tab){
			this.tab = tab;
		},
		submit(){
			this.$refs['form'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data.status = data.status ? 1 : 2;
				data.notice_status = data.notice_status ? 1 : 2;
				data.console = data.console ? 1 : 2;
				data.xhr = data.xhr ? 1 : 2;
				data.fetch = data.fetch ? 1 : 2;
				data.create_time = stringToTime(data.create_time);
				data.end_time = stringToTime(data.end_time);
				data.notice_type = data?.notice_type?.join(',') || ""

				this.submit_loading = true;
				project.create(data).then((res)=>{
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).catch((error)=>{
					this.$message.error(error.message)
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		getProjectDetail(){
			project.get({
				id:this.query.project_id
			}).then((res)=>{
				const detail = _.cloneDeep(res.detail);
				detail.status = detail.status === 1;
				detail.notice_status = detail.notice_status === 1;
				detail.console = detail.console === 1;
				detail.xhr = detail.xhr === 1;
				detail.fetch = detail.fetch === 1;
				detail.create_time = timeToString(detail.create_time);
				detail.end_time = timeToString(detail.end_time);
				detail.notice_type = detail?.notice_type?.split(',') || []
				this.form = detail;
				this.$store.commit('sentry/setProject',res.detail);
			}).catch((error)=>{
				this.$message.error(error.message)
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.setting-box{margin-top: 15px;padding: 15px;background: #fff;box-shadow: 0 0 20px 5px rgba(0,0,0,.1);
	.el-block{display: block;}
	.form-flex{display: flex;
		.el-form-item{flex: 1;}
	}
	.list{
		.li{display: flex;align-items: center;
			.el-icon-delete{margin-left: 10px;cursor: pointer;}
		}
	}
	.code{padding:5px;background: #efefef;border-radius: 5px;color:#666;line-height: 1.6;}
}
</style>
